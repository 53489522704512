<template>
  <div class="goods-box">
    <div class="flex m-b-30">
      <img :src="imagePath" class="goods-img" srcset="">
      <div>
        <div class="font-26 bold goods-name">{{info.orderType == 4 ? info.resourceName : info.productName}}</div>
        <div class="supColor font-22">{{info.orderType == 4 ? info.productName : info.productSkuName}}</div>
      </div>
      <div class="supColor count">x{{info.count}}份</div>
    </div>
    <div class="font-24 flex m-b-32">
      <div class="left supColor">联系人</div>
      <div>{{info.contactsName}} / {{info.contactsPhoneNumber}}</div>
    </div>
    <div v-if="info.reservationOrderId != '0'" class="font-24 flex m-b-32">
      <div class="left supColor">预约日期</div>
      <div>{{travelDateBegin}}~{{travelDateEnd}}（{{info.travelDateCount}}天）</div>
    </div>
    <div class="font-24 flex m-b-32">
      <div class="left supColor">核销时间</div>
      <div>{{info.usedTime}}</div>
    </div>
    <div class="font-24 flex m-b-32">
      <div class="left supColor">备注</div>
      <div class="remark">{{info.message}}</div>
    </div>
  </div>
</template>

<script>
import { resourceUrl } from "@/config";
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {
          travelDateBegin:'',
          travelDateEnd:'',
          imagePath:'',
        };
      },
    },
  },
  watch:{
    info:{
      handler(newVal,oldVal){
        this.travelDateBegin = newVal.travelDateBegin && newVal.travelDateBegin.substring(0,10)
        this.travelDateEnd = newVal.travelDateEnd && newVal.travelDateEnd.substring(0,10)
        this.imagePath = `${resourceUrl}/${newVal.imagePath}`;
      },
      immediate:true,
      deep:true
    }
  },
};
</script>

<style lang="scss" scoped>
.goods-box {
  width: 721px;
  border-radius: 10px;
  padding: 30px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  .goods-img {
    width: 130px;
    height: 130px;
    margin-right: 14px;
  }
  .count {
    align-self: center;
    margin-left: 46px;
  }
  .goods-name {
    width: 408px;
    -webkit-line-clamp: 2;
    margin-bottom: 10px;
    font-size: 28px;
    @include moreEllipsis;
  }
  .left {
    width: 130px;
    margin-right: 14px;
    flex-shrink: 0;
  }
  .remark {
    color: #ff5733;
  }
}
</style>