<template>
  <div class="bottom-box">
    <div class="button" @click="handleClick">{{text}}</div>
  </div>
</template>

<script>
export default {
    props:{
      text:{
        type:String,
        default:'按钮文字'
      }
    },
    methods:{
        handleClick(){
            this.$emit('buttonClick')
        }
    }
};
</script>

<style lang="scss" scoped>
.bottom-box {
  width: 100%;
  height: 138px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  .button {
    width: 704px;
    background: #2568ff;
    box-shadow: 0px 9px 18px 0px rgba(37, 104, 255, 0.2);
    border-radius: 42px;
    margin: 0 auto;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    padding: 20px 0;
  }
}
</style>