import request from '@/tool/axios'

// 核销记录
export function record(data) {
    return request({
        url: '/order/ticketcodeused/getusedbytenant',
        data
    })
}

// 核销详情
export function detail(data) {
    return request({
        url: '/order/ticketcodeused/checkbytenant',
        data
    })
}

// 券码产品可核销的门店(资源)
export function resource(data) {
    return request({
        url: '/product/productresource/getresources',
        data
    })
}

// 核销
export function writeOffTicket(data) {
    return request({
        url: '/order/ticketcodeused/add',
        data
    })
}

