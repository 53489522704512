<template>
  <div class="box">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" error-text="请求失败，点击重新加载" @load="getRecord">
      <goods v-for="(item,index) in list" :key="index" :info="item" class="m-b-14" />
    </van-list>
    <div id="reader" width="600px"></div>
    <div class="bottom-box">
      <div class="button" @click="open">输入券码核销</div>
      <div class="button" @click="scan">扫码核销</div>
    </div>
    <van-dialog v-model:show="show" title="输入券码" show-cancel-button @confirm="confirmCode" @cancel="close">
      <van-field v-model="ticketCodes" placeholder="请输入券码" autofocus/>
    </van-dialog>
  </div>
</template>

<script>
import goods from "./components/goods.vue";
import fixedBottomButton from "@/components/common/fixed-bottom-button.vue";
import { record, detail } from "@/api/writeOff";
import { weChatConfig } from "@/api/wechat";
import { getTenant } from "@/tool/cookie/";
export default {
  components: { goods, fixedBottomButton },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 20,
      show:false,
      ticketCodes:''
    };
  },
  mounted() {
    this.getWeChatConfig();
  },
  methods: {
    // 获取该资源的核销记录
    getRecord() {
      record({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }).then((res) => {
        this.list = this.list.concat(res.data);
        this.pageIndex++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.total) {
          this.finished = true;
        }
      });
    },
    // 微信配置
    getWeChatConfig() {
      weChatConfig(
        { pageurl: location.href },
        { Tenant: getTenant().tenantId }
      ).then((res) => {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名
          jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表
        });
        wx.checkJsApi({
          jsApiList: ["scanQRCode"],
          success: function (res) {
            console.log(res);
          },
          fail: function (res) {
            console.log(res);
          },
          error: function (res) {
            console.log(res);
          },
        });
        wx.error(function (err) {
          console.log(err);
        });
      });
    },
    scan() {
      console.log("wolaile");
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: (res) => {
          console.log("二维码信息");
          console.log(res);
          this.getInfo(res.resultStr);
        },
      });
    },
    // 打开券码
    open(){
      this.show = true
    },
    // 关闭券码
    close(){
      this.ticketCodes = ''
      this.show = false
    },
    // 确认券码
    confirmCode(){
      if(!this.ticketCodes){
        this.$toast('券码不能为空')
        return
      }
      this.getInfo(this.ticketCodes)
    },
    // 获取二维码内容
    getInfo(ticketCodes) {
      detail({
        ticketCodes: ticketCodes.split(","),
      })
        .then((res) => {
          this.close()
          this.$router.push({
            path: "/writeOffDetail",
            query: {
              ticketCodes,
            },
          });
        })
        .catch((err) => {
          this.close()
          err.businessErrorType && this.$toast(err.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding-bottom: 138px;
  .name-box {
    padding: 22px 32px;
    background-color: #f0f0f0;
    margin-bottom: 18px;
    position: sticky;
    top: 0;
    .drop {
      width: 16px;
      height: 10px;
      margin-left: 8px;
    }
  }
  
  .bottom-box {
    width: 100%;
    height: 138px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button {
      width: 344px;
      background: #2568ff;
      box-shadow: 0px 9px 18px 0px rgba(37, 104, 255, 0.2);
      border-radius: 42px;
      margin: 0 auto;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      padding: 20px 0;
    }
  }
}
</style>